<template>
    <b-modal title="Uporabnik" v-model="modalActive" :hide-header-close="loading" :no-close-on-backdrop="loading" :no-close-on-esc="loading" size="md">
        <template #default>
            <b-overlay :show="loading">
                <validation-observer ref="validation">
                    <div class="row">
                        <b-form-group class="col-12" label="Email">
                            <validation-provider #default="{ errors }" name="email">
                                <v-select v-model="user.user_id" :disabled="editing" taggable :clearable="false" :reduce="ele => ele.id" @option:created="newOptionAdded" :filter="fuseSearch" label="email" :options="unused_users" appendToBody>
                                    <template #selected-option="{name, email}" style="max-width: 100% !important;" >
                                        {{email}}
                                    </template>
                                    <template v-slot:option="{name, email}">
                                        {{email}}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </validation-observer>

                <div style="font-size: 20px; font-weight: bold;" class="mb-1">
                    Pravice
                </div>

                <div class="d-flex justify-content-between mb-2">
                    <b-button variant="warning" @click="removeAllPermissions">
                        Odstrani vse
                    </b-button>

                    <b-button variant="primary" @click="addAllPermissions">
                        Dodaj vse
                    </b-button>
                </div>

                <b-form-checkbox-group
                    v-model="user.permissions"
                >
                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Dashboard
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.Dashboard" @change="updatedPermissions($permissions.Dashboard)"/>
                            </div>            </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Materialno poslovanje
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled/Urejanje</span> <b-form-checkbox :value="$permissions.InventoryManagement" @change="updatedPermissions($permissions.InventoryManagement)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Fakture
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.InvoicesRead" @change="updatedPermissions($permissions.InvoicesRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.InvoicesWrite" @change="updatedPermissions($permissions.InvoicesWrite)"/>
                            </div>
                            <span class="mr-1">Storno</span> <b-form-checkbox :value="$permissions.InvoicesReversal" @change="updatedPermissions($permissions.InvoicesReversal)"/>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Reporting
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.ReportingRead" @change="updatedPermissions($permissions.ReportingRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.ReportingWrite" @change="updatedPermissions($permissions.ReportingWrite)"/>
                            </div>
                            <span class="mr-1">Storno</span> <b-form-checkbox :value="$permissions.IssuedBillsReversal" @change="updatedPermissions($permissions.IssuedBillsReversal)"/>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Odstranjeno
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.DeletedRead" @change="updatedPermissions($permissions.DeletedRead)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Podatki
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.GeneralRead" @change="updatedPermissions($permissions.GeneralRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.GeneralWrite" @change="updatedPermissions($permissions.GeneralWrite)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Šifranti
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.ProductsRead" @change="updatedPermissions($permissions.ProductsRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.ProductsWrite" @change="updatedPermissions($permissions.ProductsWrite)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje cen</span> <b-form-checkbox :value="$permissions.PricesWrite" @change="updatedPermissions($permissions.PricesWrite)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Konfiguracija
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.ConfigurationRead" @change="updatedPermissions($permissions.ConfigurationRead)"/>
                            </div>                <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.ConfigurationWrite" @change="updatedPermissions($permissions.ConfigurationWrite)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            POS Konfiguracija
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.PosConfigurationRead" @change="updatedPermissions($permissions.PosConfigurationRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.PosConfigurationWrite" @change="updatedPermissions($permissions.PosConfigurationWrite)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1">
                        <div style="font-size: 14px; font-weight: bold;">
                            Konfiguracija uporabnikov
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.UserConfigurationRead" @change="updatedPermissions($permissions.UserConfigurationRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.UserConfigurationWrite" @change="updatedPermissions($permissions.UserConfigurationWrite)"/>
                            </div>
                        </div>
                    </div>

                    <div class="mb-1" v-if="returnable_packaging_enabled">
                        <div style="font-size: 14px; font-weight: bold;">
                            Povratna embalaža
                        </div>

                        <div class="d-flex">
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Ogled</span> <b-form-checkbox :value="$permissions.ReturnablePackagingRead" @change="updatedPermissions($permissions.ReturnablePackagingRead)"/>
                            </div>
                            <div class="mr-2 d-flex">
                                <span class="mr-1">Urejanje</span> <b-form-checkbox :value="$permissions.ReturnablePackagingWrite" @change="updatedPermissions($permissions.ReturnablePackagingWrite)"/>
                            </div>
                        </div>
                    </div>
                </b-form-checkbox-group>
            </b-overlay>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="confirm" :disabled="loading">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Uredi</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Dodaj</span>
                </span>
                <span v-if="loading">
                    <b-spinner class="ml-1" style="height: 15px; width: 15px"></b-spinner>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import Fuse from 'fuse.js'
    import {BButton, BFormGroup, BModal, BFormCheckbox, BFormCheckboxGroup, BOverlay, BSpinner} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'

    export default {
        components: {
            BButton,
            BSpinner,
            ValidationProvider,
            ValidationObserver,
            // BFormTimepicker,
            BFormCheckbox,
            BFormCheckboxGroup,
            BFormGroup,
            // BFormInput,
            BModal,
            BOverlay,
            vSelect
        },
        props: {
            client_id: {
                type: String
            },
            already_added_users: {
                type: Array
            },
            returnable_packaging_enabled:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            unused_users() {
                const array = []

                for (const item of this.users) {
                    if (this.already_added_users.findIndex(ele => ele.email === item.email) < 0) {
                        array.push(item)
                    } else if (this.editing && item.id === this.user.user_id) {
                        array.push(item)
                    }
                }

                return array
            }
        },
        data() {
            return {
                loading: false,
                user: {
                    user_id: '',
                    permissions: []
                },
                users: [],
                modalActive: false,
                editing: false
            }
        },
        methods: {
            updatedPermissions(permission) {

                const mod = parseInt(permission % 100)

                if (mod === 1 && !this.user.permissions.includes(permission)) {
                    this.user.permissions = this.user.permissions.filter(ele => ele < permission || ele > permission + 10)
                } else {
                    if (mod >= 2) {
                        if (!this.user.permissions.includes(permission - 1)) {
                            this.user.permissions.push(permission - 1)
                        } else {
                            this.user.permissions = this.user.permissions.filter(ele => ele < permission + 1 || ele > permission + 10)
                        }
                    }

                    if (mod >= 3) {
                        if (!this.user.permissions.includes(permission - 2)) {
                            this.user.permissions.push(permission - 2)
                        } else {
                            this.user.permissions = this.user.permissions.filter(ele => ele < permission + 1 || ele > permission + 10)
                        }
                    }
                }

            },
            async editUser() {
                try {
                    this.loading = true
                    this.user.client_id = this.client_id
                    await this.$http.post(`/api/admin/v1/client/user/${this.client_id}`, this.user)
                    this.$printSuccess('Uporabnik uspešno posodobljen')
                    this.hide()
                    this.$emit('added')
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.loading = false
                }
            },
            confirm() {
                if (this.editing) {
                    this.editUser()
                } else {
                    this.addUser()
                }
            },
            generateUniqueId() {
                const idLength = 30

                const timestamp = new Date().getTime().toString(36)

                const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                let randomString = ''
                for (let i = 0; i < idLength - timestamp.length; i++) {
                    const randomIndex = Math.floor(Math.random() * randomChars.length)
                    randomString += randomChars.charAt(randomIndex)
                }

                return timestamp + randomString
            },
            newOptionAdded(val) {
                let email = ''

                if (typeof val === 'string') email = val
                else email = val.email

                // eslint-disable-next-line no-useless-escape
                const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                const emailValid = regExp.test(email)

                if (!emailValid) {
                    this.$printWarning('Vnesen email ni veljaven')
                    return
                }

                const id = this.generateUniqueId()
                this.users.push({
                    email,
                    id
                })

                this.$nextTick(() => {
                    this.user.user_id = id
                })
            },
            addAllPermissions() {
                const allPermissions = [
                    this.$permissions.Dashboard,
                    this.$permissions.InventoryManagement,
                    this.$permissions.InvoicesRead,
                    this.$permissions.InvoicesWrite,
                    this.$permissions.InvoicesReversal,
                    this.$permissions.ReportingRead,
                    this.$permissions.ReportingWrite,
                    this.$permissions.IssuedBillsReversal,
                    this.$permissions.GeneralRead,
                    this.$permissions.GeneralWrite,
                    this.$permissions.ProductsRead,
                    this.$permissions.ProductsWrite,
                    this.$permissions.PricesWrite,
                    this.$permissions.ConfigurationRead,
                    this.$permissions.ConfigurationWrite,
                    this.$permissions.PosConfigurationRead,
                    this.$permissions.PosConfigurationWrite,
                    this.$permissions.UserConfigurationRead,
                    this.$permissions.UserConfigurationWrite,
                    this.$permissions.DeletedRead,
                    this.$permissions.ReturnablePackagingRead,
                    this.$permissions.ReturnablePackagingWrite

                ]

                this.user.permissions = []
                this.user.permissions.push(...allPermissions)
            },
            removeAllPermissions() {
                this.user.permissions = []
            },
            async addUser() {
                try {
                    this.loading = true

                    const item = this.users.find(ele => ele.id === this.user.user_id)

                    if (item) {
                        this.user.email = item.email
                    } else {
                        return
                    }

                    await this.$http.post(`/api/admin/v1/client/${this.client_id}`, this.user)
                    this.$printSuccess('Uporabnik uspešno dodan')
                    this.hide()
                    this.$emit('added')
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.loading = false
                }
            },
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name', 'email'],
                    shouldSort: true,
                    threshold: 0.1
                })
                return search.length ? fuse.search(search).map(({item}) => item) : fuse.list
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/admin/v1/users')

                    this.users = res.data
                } catch (err) {
                    this.$printError(err)
                }
            },
            open(editing, data) {
                this.modalActive = true
                this.editing = editing
                this.loading = false

                if (data) {
                    this.user = JSON.parse(JSON.stringify(data))
                } else {
                    this.user =  {
                        user_id: '',
                        permissions: []
                    }
                }
            },
            hide() {
                this.modalActive = false
            }
        },
        mounted() {
            this.loadData()
        }

    }

</script>
